import React, { useContext, useMemo } from 'react'
import { ConfigContext } from 'src/store/ConfigContext'

export const UserContext = React.createContext({
  selectedBizModel: null
})

const UserProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const selectedBizModel = sessionContext?.user?.selectedBizModel || null
  const accountId = sessionContext?.tenant?.resourceId || null

  const state = useMemo(
    () => ({
      selectedBizModel,
      accountId
    }),
    [selectedBizModel, accountId]
  )

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  )
}

export default UserProvider
