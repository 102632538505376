import {
  DEFAULT_ERROR_CODE,
  DHP_ERROR_CODE,
  DHP_NON_200_ERROR_CODE,
  SERVICE_ID
} from '../config/constants'
import { languageFallback, localeFallback } from '../assets/locale'

const packageJson = require('../../package.json')

const logWithPrefix = (severity, log) => {
  console[severity](
    `DeviceOnboarding - ${packageJson.version} - `,
    typeof log === 'string' || log instanceof String ? log : JSON.stringify(log)
  )
}

export const Logger = {
  debug: (log) => logWithPrefix('debug', log),
  log: (log) => logWithPrefix('log', log),
  info: (log) => logWithPrefix('info', log),
  warn: (log) => logWithPrefix('warn', log),
  error: (log) => logWithPrefix('error', log)
}

export const generateError = (error) => {
  const errorCode = DHP_ERROR_CODE[error.errorType]
    ? DHP_ERROR_CODE[error.errorType]
    : error.statusCode
    ? DHP_NON_200_ERROR_CODE
    : DEFAULT_ERROR_CODE

  return {
    errorCode: `${SERVICE_ID}.${errorCode}`,
    errorDescription: `${error.errorType || error?.statusCode} - ${
      error.reason ? error.reason : 'Error calling DHP plugin (no reason)'
    }`,
    errorCodeLabel: error.statusCode
      ? `${errorCode}_${error.statusCode}`
      : errorCode,
    payload: error.payload,
    resourceUri: error.resourceUri
  }
}

export const isDHPError = (response) => {
  return !!(
    response?.errorType || range(200, 299).indexOf(response?.statusCode) === -1
  )
}

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

export const generateApiError = (error, resourceUri = null) => {
  const [prefix, status, code] = error.split('_')
  return {
    errorCode: `${SERVICE_ID}.${prefix}.${status}.${code}`,
    errorDescription: `${prefix} - ${status} - ${code}`,
    errorCodeLabel: error,
    statusCode: parseInt(status),
    resourceUri: resourceUri
  }
}

export function getHighestResolutionImage(imagesArray) {
  if (!imagesArray) return null

  let highestResolutionImage = null
  let highestResolution = 0

  for (let image of imagesArray) {
    const resolutionComponent = image.split('/').pop()
    const size = resolutionComponent.split('.')[0].split('x')
    const resolution = parseInt(size[0]) * parseInt(size[1])
    if (resolution > highestResolution) {
      highestResolution = resolution
      highestResolutionImage = image
    }
  }

  return highestResolutionImage
}

export const findLocaleFallback = (language, country) => {
  const locale = `${language}_${country}`
  if (localeFallback[locale]) {
    return localeFallback[locale]
  }
  return languageFallback[language] ? languageFallback[language] : 'en_US'
}
