import React, { useContext } from 'react'
import classes from './Spinner.module.scss'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator/progress_indicator'
import { ConfigContext } from '../store/ConfigContext'
import { UserContext } from 'src/store/UserContext'
import { BIZ_MODEL } from 'src/config/constants'

export const Spinner = () => {
  const { selectedBizModel } = useContext(UserContext)
  const { direction, getText } = useContext(ConfigContext)
  const bizModelCopy =
    selectedBizModel === BIZ_MODEL.E2E ? 'activate' : 'register'
  return (
    <>
      <div
        className={`${classes.spinner}`}
        dir={direction}
      >
        <div className={classes.progressIndicator}>
          <ProgressIndicator
            data-testid="spinner"
            appearance="circular"
            behavior="indeterminate"
          />
          {bizModelCopy && (
            <label className={classes.label}>
              {getText(`spinner.${bizModelCopy}.0`)}
            </label>
          )}
        </div>
      </div>
    </>
  )
}
