// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GwyAfiZHe1XY3TWTC1nD{overflow:hidden;height:100%}.AwWDYKNdExNyCRnWhJyw{align-items:center;margin:auto;padding:0}.AwWDYKNdExNyCRnWhJyw .GaMKoCea7fT0IgeUZrKA{display:flex;justify-content:center;align-items:center;flex-direction:column;min-height:calc(80vh - 16px)}.AwWDYKNdExNyCRnWhJyw .GaMKoCea7fT0IgeUZrKA div[role=progressbar]{width:50px;height:50px}.AwWDYKNdExNyCRnWhJyw .EB6BJDJ0Ab3joysK0LOJ{display:block;margin-top:3%}", "",{"version":3,"sources":["webpack://./src/components/Spinner.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA,CACA,WAAA,CAGD,sBACC,kBAAA,CACA,WAAA,CACA,SAAA,CAEA,4CACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,4BAAA,CAEA,kEACC,UAAA,CACA,WAAA,CAIF,4CACC,aAAA,CACA,aAAA","sourcesContent":[".container {\n\toverflow: hidden;\n\theight: 100%;\n}\n\n.spinner {\n\talign-items: center;\n\tmargin: auto;\n\tpadding: 0;\n\n\t.progressIndicator {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tflex-direction: column;\n\t\tmin-height: calc(80vh - 2 * 8px);\n\n\t\tdiv[role='progressbar'] {\n\t\t\twidth: 50px;\n\t\t\theight: 50px;\n\t\t}\n\t}\n\n\t.label {\n\t\tdisplay: block;\n\t\tmargin-top: 3%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GwyAfiZHe1XY3TWTC1nD",
	"spinner": "AwWDYKNdExNyCRnWhJyw",
	"progressIndicator": "GaMKoCea7fT0IgeUZrKA",
	"label": "EB6BJDJ0Ab3joysK0LOJ"
};
export default ___CSS_LOADER_EXPORT___;
