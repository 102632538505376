export const EMPTY_FUNCTION = () => {
  // Intentional empty function to avoid sonarqube code smells
}

// Onboarding Director specific
export const SERVICE_ID = 'omig-jwv-device-onboarding'

export const DEVICE_NOT_SUPPORTED = 'notSupported'
export const DEVICE_CDM_ONLY = 'cdmOnly'
export const DEVICE_LEDM_ONLY = 'ledmOnly'
export const DEVICE_LEDM_AND_CDM = 'ledmAndCdm'

export const CACHE_STRATEGY_CACHEFIRST = 'cacheFirst'
export const MAX_AGE_IN_SECONDS = 1800

export const DELAY_2_SEC = 2 * 1000
export const DELAY_5_SEC = 5 * 1000
export const DELAY_2_MIN = 2 * 60 * 1000
export const DELAY_3_MIN = 3 * 60 * 1000

export const RESULT = {
  FAILURE: 'failure',
  SUCCESS: 'success',
  INELIGIBLE: 'ineligible',
  ALREADY_PERFORMED: 'alreadyPerformed'
}

export const DISABLED = 'disabled'

// Non-DeviceHttpProxy custom error codes
export const VALIDATE_DEVICE_TIMEOUT_ERROR = 'OW_XXX_VD00002'
export const VALIDATE_DEVICE_FALLBACK_ERROR = 'VD00001'
export const REGISTER_DEVICE_FALLBACK_ERROR = 'RD00001'
export const REGISTER_DEVICE_INVALID_DATA_ERROR = 'OW_200_RD00002'
export const TENANT_INTERFACE_ERROR = 'JS_XXX_TI00001'
export const WEB_SERVICES_TIMEOUT_ERROR = 'OW_XXX_EW00001'
export const WEB_SERVICES_DISABLED_BY_ADMIN = 'OW_XXX_EW00002'
export const WEB_SERVICES_REG_HREF_DOES_NOT_EXIST = 'OW_XXX_EW00004'

// DeviceHttpProxy error code mapping
export const DEFAULT_ERROR_CODE = 'OW_DHP00001'
export const DHP_NON_200_ERROR_CODE = 'OW_DHP00005'

export const DHP_REQUEST_REJECTED_ERROR = 'requestRejected'
export const DHP_DISCOVERY_TREE_EMPTY_ERROR = 'discoveryTreeEmpty'
export const DHP_AVATAR_REGISTRATION_REQUIRED_ERROR =
  'avatarRegistrationRequired'
export const DHP_CLAIM_POSTCARD_REQUIRED_ERROR = 'claimPostcardRequired'
export const DHP_GEN_TWO_MANIFEST_REQUIRED_ERROR =
  'genTwoCloudServiceManifestRequired'
export const DHP_GEN_TWO_MANIFEST_EMPTY_ERROR =
  'genTwoCloudServiceManifestEmpty'
export const DHP_EPRINT_MANIFEST_REQUIRED_ERROR = 'ePrintManifestRequired'
export const DHP_EPRINT_MANIFEST_EMPTY_ERROR = 'ePrintManifestEmpty'
export const DHP_CLAIM_POSTCARD_EMPTY_ERROR = 'claimPostcardEmpty'

export const DHP_ERROR_CODE = {
  invalidArgument: 'OW_DHP00002',
  dataValveError: 'OW_DHP00003',
  unableToDecode: 'OW_DHP00004', // non-DHP error type
  invalidSession: 'HS_DHP00001',
  sessionNotActive: 'HS_DHP00002',
  deviceOffline: 'HS_DHP00003',
  requestRejected: 'HS_DHP00004', // non-DHP error type
  requestTimedOut: 'FW_DHP00001',
  cancelled: 'FW_DHP00002',
  unknownError: 'FW_DHP00003',
  discoveryTreeEmpty: 'FW_DHP00004', // non-DHP error type
  avatarRegistrationRequired: 'FW_DHP00005', // non-DHP error type
  claimPostcardRequired: 'FW_DHP00006', // non-DHP error type
  genTwoCloudServiceManifestRequired: 'FW_DHP00007', // non-DHP error type
  genTwoCloudServiceManifestEmpty: 'FW_DHP00008', // non-DHP error type
  ePrintManifestRequired: 'FW_DHP00009', // non-DHP error type
  ePrintManifestEmpty: 'FW_DHP00010', // non-DHP error type
  claimPostcardEmpty: 'FW_DHP00011' // non-DHP error type
}

// Analytics
const BASE_PATH = '/DeviceOnboarding/'

const ACTIVITY = 'HardwareSetup-v01'

const ERROR_CODE = 'ErrorCode'

export const ANALYTICS = {
  ACTIVITY,
  BASE_PATH,
  ERROR_CODE,
  SCREENS: {
    START: {
      screenName: 'Start',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    ACTIVATION: {
      screenName: 'Activation',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    ACTIVATION_FAILED: {
      screenName: 'ActivationFailed',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    ACTIVATION_SUCCESS: {
      screenName: 'ActivationSuccess',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    }
  },
  CONTROLS: {
    EXIT_SETUP: 'ExitSetupButton',
    RETRY: 'RetryButton',
    ENABLE_WEB_SERVICES: 'EnableWebServices',
    DEVICE_VALIDATION: 'DeviceValidation',
    DEVICE_REGISTRATION: 'DeviceRegistration',
    SUPPORT_BUTON: 'SupportButton'
  },
  MODES: {
    INTERFACE: {
      [true]: 'Ledm',
      [false]: 'Cdm'
    }
  },
  SUPPORT_LINK: 'SupportLink'
}

// Fallback resource URIs
export const RESOURCE_URI_FALLBACK = {
  CDM: {
    avatarRegistration: '/cdm/cloudServices/v1/avatarRegistration',
    claimPostcard: '/cdm/cloudServices/v1/claim/postcard',
    discoveryTree: '/cdm/servicesDiscovery',
    fingerprint: '/cdm/deviceProvisioning/v1/printerFingerprint',
    internetDiagnostics: '/cdm/network/v1/internetDiagnostics',
    systemIdentity: '/cdm/system/v1/identity'
  },
  LEDM: {
    discoveryTree: '/DevMgmt/DiscoveryTree.xml',
    ePrintManifest: '/ePrint/ePrintManifest.xml',
    ePrintConfigDyn: '/ePrint/ePrintConfigDyn.xml',
    gen2CloudServiceManifest: '/Gen2CloudService/Gen2CloudServiceManifest.xml',
    gen2CloudServicePostcard: '/Gen2CloudService/Claim/Postcard',
    productConfigDyn: '/DevMgmt/ProductConfigDyn.xml',
    iccManifest: '/Utilities/UtilitiesManifest.xml',
    iccStatus: '/Utilities/ICCStatus',
    iccJob: '/Utilities/ICCJob'
  }
}
export const REGISTER_DEVICE_API_RESOURCE_URI_VALUE = '/$accountId/devices'
export const VALIDATE_DEVICE_API_RESOURCE_URI_VALUE =
  '/$accountId/devices/validate'

// Monitoring
export const SPAN_FLOW_RESOURCE = 'flow.resource'
export const SPAN_FLOW_PAYLOAD = 'flow.payload'
export const SPAN_MODEL_NAME = 'model.name'
export const SPAN_MODEL_NUMBER = 'model.number'
export const SPAN_WORKFLOW_NAME = 'workflow.name'
export const SUPPORT_URL_GENERIC = 'https://hp.com/support'
export const SUPPORT_URL_UNABLE_TO_REGISTER =
  'https://support.hp.com/document/ish_4411816-4315131-16?openCLC=true'
export const STATUS_RESULT = {
  SUCCESS: 'success',
  ERROR: 'error'
}
export const ACTIVATION_FAILED_ERRORS = {
  EB000U0004: 'EB000U0004',
  EB000U0405: 'EB000U0405'
}

export const BIZ_MODEL = {
  FLEX: 'Flex',
  E2E: 'E2E'
}

export const REGISTRATION_STATE = {
  REGISTERED: 'registered',
  UNREGISTERED: 'unregistered',
  REGISTERING: 'registering',
  NEW_REGISTRATION: 'newRegistration',
  RETRYING: 'retrying',
  CHECK_IN_PROGRESS: 'checkInProgress',
  PROCESSING: 'processing'
}

// LEDM Payloads
export const EPRINT_REGISTRATION_PAYLOAD =
  '<?xml version="1.0" encoding="UTF-8"?><ep:ePrintConfigDyn xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:dd="http://www.hp.com/schemas/imaging/con/dictionaries/1.0/" xmlns:ep="http://www.hp.com/schemas/imaging/con/eprint/2010/04/30" xsi:schemaLocation="http://www.hp.com/schemas/imaging/con/eprint/2010/04/30 ../../schemas/ePrintConfigDyn.xsd"><ep:RegistrationState>registering</ep:RegistrationState><ep:RegistrationDetails><ep:RegistrationTriggeredBy>software</ep:RegistrationTriggeredBy></ep:RegistrationDetails></ep:ePrintConfigDyn>'

// Launch Darkly
export const FEATURE_FLAG_CLIENT = 'ld-onboarding-migration-client'
export const FEATURE_FLAG_KEYS = {
  enableIcc: 'omig-release-enableicc'
}

export const ICC_STATUS_ALLOWED = {
  connected: 'connected',
  failed: 'failed',
  checkCancelled: 'checkCancelled',
  checkForbidden: 'checkForbidden',
  notApplicable: 'notApplicable'
}

export const ICC_FAILURE_REASON_ALLOWED = {
  networkNotConnected: 'networkNotConnected',
  ipNotRoutable: 'ipNotRoutable',
  gatewayNotConfigured: 'gatewayNotConfigured',
  gatewayNotReachable: 'gatewayNotReachable',
  dnsNotConfigured: 'dnsNotConfigured',
  dnsNotResolved: 'dnsNotResolved',
  dnsNotReachable: 'dnsNotReachable',
  proxyNotConfigured: 'proxyNotConfigured',
  proxyURLResolutionFailed: 'proxyURLResolutionFailed',
  proxyConnectionFailed: 'proxyConnectionFailed',
  proxyRequiresAuthentication: 'proxyRequiresAuthentication',
  proxyCredentialsIncorrect: 'proxyCredentialsIncorrect',
  serverConnectionFailed: 'serverConnectionFailed',
  connected: 'connected',
  notApplicable: 'notApplicable'
}

export const CLOUD_SERVICES_OPTIONS = {
  ENABLED: 'true',
  DISABLED: 'false'
}

export const CDM_DEFAULT_VERSION = '1.0.0'
